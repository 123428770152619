<template>
  <page-container title="电子地图" :breadcrumb="breadcrumb">
    <div class="bg-white">
      <div class="title">电子地图</div>
      <a-checkbox-group size="small" style="margin-left: 1.5%" :defaultValue="currTab" @change="onChange">
        <a-row>
<!--        <a-checkbox value="0">-->
<!--          全部-->
<!--        </a-checkbox>-->
        <a-checkbox value="1">
          <img src="@/assets/image/lot/ic-dt-point.png" style="height: 25px"/>电梯
        </a-checkbox>
        <a-checkbox value="2">
          <img src="@/assets/image/lot/ic-sydw-point.png" style="height: 25px"/>使用单位
        </a-checkbox>
        <a-checkbox value="3">
          <img src="@/assets/image/lot/ic-jgdw-point.png" style="height: 25px"/>监管单位
        </a-checkbox>
<!--        <a-checkbox value="4">-->
<!--          <img src="@/assets/image/lot/ic-wbdw-point.png" style="height: 25px"/>维保单位-->
<!--        </a-checkbox>-->
<!--        <a-checkbox value="5">-->
<!--          <img src="@/assets/image/lot/ic-jydw-point.png" style="height: 25px"/>检验单位-->
<!--        </a-checkbox>-->
<!--        <a-checkbox value="6">-->
<!--          <img src="@/assets/image/lot/ic-jcdw-point.png" style="height: 25px"/>检测单位-->
<!--        </a-checkbox>-->
<!--        <a-checkbox value="7">-->
<!--          <img src="@/assets/image/lot/ic-96333-point.png" style="height: 25px"/>救援单位-->
<!--        </a-checkbox>-->
        </a-row>
      </a-checkbox-group>
    </div>
    <div class="main-container box-container">
      <!-- 这里是搜索 -->
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" :class="{'w-25':currTab==='1'}">
            <template>
              <a-form-model-item label="电梯救援码" prop="liftrescue">
                <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
              </a-form-model-item>
<!--              <a-form-model-item label="使用单位" prop="userdepname">-->
<!--                <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item label="维保单位" prop="maintenancedepname">-->
<!--                <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="区域" prop="areacode">
                <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
              </a-form-model-item>
<!--              <a-form-model-item label="类型" prop="deptype">-->
<!--                <a-select placeholder="请选择" v-model="deptype">-->
<!--                  <a-select-option :value="0">请选择</a-select-option>-->
<!--                  <a-select-option :value="1">使用单位</a-select-option>-->
<!--                  <a-select-option :value="2">监管单位</a-select-option>-->
<!--                  <a-select-option :value="3">维保单位</a-select-option>-->
<!--                  <a-select-option :value="4">检验单位</a-select-option>-->
<!--                  <a-select-option :value="5">检测单位</a-select-option>-->
<!--                  <a-select-option :value="6">96333</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepid">-->
<!--                <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维护单位" prop="maintenancedepid">-->
<!--                <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='3')" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维护单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--              </a-form-model-item>-->
            </template>
<!--            <template>-->
<!--              <a-form-model-item label="机构名称" prop="userdepname">-->
<!--                <a-input v-model.trim="queryParams.userdepname" placeholder="请输入"></a-input>-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item label="区域" prop="areacode">-->
<!--                <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>-->
<!--              </a-form-model-item>-->
<!--            </template>-->
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
      <div class="left-box" v-if="showResult">
        <div class="box-flex">
          <span class="box-title">查询结果</span>
          <a-icon type="close" class="cursor" @click="cancelBox" />
        </div>
        <a-table 
          :rowKey="(record)=>record.userdepid||record.liftid" 
          :columns="columns" :data-source="tableData" 
          row-key="liftid" 
          :loading="tableLoading"
          :customRow="customRow"
          :pagination="false">
          <span slot="adress" slot-scope="value, record" class="status-view">
            {{record.adress||record.address}}
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
      <!-- 这里是地图部分 -->
      <div id="elevatorMap"></div>
    </div>
  </page-container>
</template>

<script>
  import { mapState } from 'vuex'
  import deptselect from '@/mixins/deptselect'
  import pagination from '@/mixins/pagination'
  import areaselect from '@/mixins/areaselect'
  import { getAllUserdepLists,getAllLiftinfoLists } from "@/api/wlw";
  import { getLiftinfoById,getLiftinfoListByCondition,getUserdepListByCondition } from "@/api/jcgn";
  import DtUnSelect from "@/assets/image/lot/ic-dt-point.png";
  import UnitSelect from "@/assets/image/lot/ic-wbdw-point.png";
  import SydwSelect from "@/assets/image/lot/ic-sydw-point.png";
  import JgdwSelect from "@/assets/image/lot/ic-jgdw-point.png";
  import JydwSelect from "@/assets/image/lot/ic-jydw-point.png";
  import JcdwSelect from "@/assets/image/lot/ic-jcdw-point.png";
  import SosSelect from "@/assets/image/lot/ic-96333-point.png";
  export default {
    name: 'elevatorMap',
    mixins: [deptselect,pagination,areaselect],
    data() {
      return {
        Map:null,
        breadcrumb:[
          {
            name: '设施设备物联网',
            path: ''
          },
          {
            name: '电梯物联网',
            path: ''
          },
          {
            name: '电子地图',
            path: ''
          },
        ],
        showResult:false,
        tableLoading:false,
        columns:[
          {
            width:140,
            title: '安装地址',
            dataIndex: 'adress',
            key: 'adress',
            scopedSlots: { customRender: 'adress' }
          },{
            title: '使用单位',
            dataIndex: 'userdepname',
            key: 'userdepname',
          }
        ],
        deptype:null,
        tableData:[],
        currTab:["1"],
        userdepidCascaderSelected: [],
        maintenancedepidCascaderSelected:[],
        queryAreaSelected: [],
        queryParams: {
          liftrescue:"",
          userdepid:"",
          maintenancedepid:"",
          userdepname:"",
          maintenancedepname:"",
          areacode:""
        }
      }
    },
    watch: {
      queryAreaSelected(val) {
        if(val && val.length) {
          this.queryParams.areacode = val[val.length-1];
        }else {
          this.queryParams.areacode = '';
        }
      },
      userdepidCascaderSelected(val) {
        if(val && val.length) {
          this.queryParams.userdepid = val[val.length-1];
        }else {
          this.queryParams.userdepid = '';
        }
      },
      maintenancedepidCascaderSelected(val) {
        if(val && val.length) {
          this.queryParams.maintenancedepid = val[val.length-1];
          // this.initMaintenancegroupidOptions();
        }else {
          this.queryParams.maintenancedepid = '';
          this.maintenancegroupidOptions = [];
        }
      },
    },
    computed: {
      ...mapState({
        userInfo: state => state.userInfo
      }),
    },
    mounted () {
      this.Map = new window.AMap.Map('elevatorMap', {
        resizeEnable: false, //是否监控地图容器尺寸变化
        zoom:10, //初始化地图层级
      });
      getAllLiftinfoLists({}).then(res=>{
        if(res&&res.returncode=='0'){
          // const res0=res
          res.item.forEach(item=>this.addMarker(item,'dt'))
        }
      })
      this.$once('hook:beforeDestroy',()=>{
        this.Map&&this.Map.destroy()
      })
    },
    methods: {
      cancelBox(){
        this.showResult = false
      },
      customRow(record, index) {
        return {
          on: {
            click: event => {
              let o = {target:{item:record}}
              this.markerClick(o,record.liftid?'selfdt':'selfdep')
            },
          },
        }
      },
      onChange(checkedValues) {
        this.resetQueryParams()
        // if(checkedValues==0){
        //   this.Map.clearMap();
        //   this.getAllMapDatas();
        // }
        let arr=checkedValues
        if(arr.indexOf("1")>-1){
          // this.Map.clearMap();
          getAllLiftinfoLists({}).then(res=>{
            if(res&&res.returncode=='0'){
              res.item.forEach(item=>this.addMarker(item,'dt'))
            }
          })
        }else{
          this.Map.clearMap();
        }
        if(arr.indexOf("2")>-1){
          // this.Map.clearMap();
          getAllUserdepLists({deptype:1}).then(res=>{
            if(res&&res.returncode=='0'){
              res.item.forEach(item=>this.addMarker(item,'sydw'))
            }
          })
        }else{
          this.Map.clearMap();
        }
        if(arr.indexOf("3")>-1){
          // this.Map.clearMap();
          getAllUserdepLists({deptype:2}).then(res=>{
            if(res&&res.returncode=='0'){
              res.item.forEach(item=>this.addMarker(item,'jgdw'))
            }
          })
        }else{
          this.Map.clearMap();
        }
        if(arr.indexOf("4")>-1){
          // this.Map.clearMap();
          getAllUserdepLists({deptype:3}).then(res=>{
            if(res&&res.returncode=='0'){
              res.item.forEach(item=>this.addMarker(item,'unit'))
            }
          })
        }else{
          this.Map.clearMap();
        }
        if(arr.indexOf("5")>-1){
          // this.Map.clearMap();
          getAllUserdepLists({deptype:4}).then(res=>{
            if(res&&res.returncode=='0'){
              res.item.forEach(item=>this.addMarker(item,'jydw'))
            }
          })
        }else{
          this.Map.clearMap();
        }
        if(arr.indexOf("6")>-1){
          // this.Map.clearMap();
          getAllUserdepLists({deptype:5}).then(res=>{
            if(res&&res.returncode=='0'){
              res.item.forEach(item=>this.addMarker(item,'jcdw'))
            }
          })
        }else{
          this.Map.clearMap();
        }
        if(arr.indexOf("7")>-1){
          // this.Map.clearMap();
          getAllUserdepLists({deptype:6}).then(res=>{
            if(res&&res.returncode=='0'){
              res.item.forEach(item=>this.addMarker(item,'sos'))
            }
          })
        }else{
          this.Map.clearMap();
        }
      },
      // handleChange(key) {
      //   this.currTab = key
      //   this.resetQueryParams()
      //   if(key==0){
      //     this.Map.clearMap();
      //     this.getAllMapDatas();
      //   }else if(key==1){
      //     this.Map.clearMap();
      //     getAllLiftinfoLists({}).then(res=>{
      //       if(res&&res.returncode=='0'){
      //         // const res0=res
      //         res.item.forEach(item=>this.addMarker(item,'dt'))
      //       }
      //     })
      //   }else if(key==2){
      //     this.Map.clearMap();
      //     getAllUserdepLists({deptype:1}).then(res=>{
      //       if(res&&res.returncode=='0'){
      //         // const res0=res
      //         res.item.forEach(item=>this.addMarker(item,'sydw'))
      //       }
      //     })
      //   }else if(key==3){
      //     this.Map.clearMap();
      //     getAllUserdepLists({deptype:2}).then(res=>{
      //       if(res&&res.returncode=='0'){
      //         // const res0=res
      //         res.item.forEach(item=>this.addMarker(item,'jgdw'))
      //       }
      //     })
      //   }else if(key==4){
      //     this.Map.clearMap();
      //     getAllUserdepLists({deptype:3}).then(res=>{
      //       if(res&&res.returncode=='0'){
      //         // const res0=res
      //         res.item.forEach(item=>this.addMarker(item,'unit'))
      //       }
      //     })
      //   }else if(key==5){
      //     this.Map.clearMap();
      //     getAllUserdepLists({deptype:4}).then(res=>{
      //       if(res&&res.returncode=='0'){
      //         // const res0=res
      //         res.item.forEach(item=>this.addMarker(item,'jydw'))
      //       }
      //     })
      //   }else if(key==6){
      //     this.Map.clearMap();
      //     getAllUserdepLists({deptype:5}).then(res=>{
      //       if(res&&res.returncode=='0'){
      //         res.item.forEach(item=>this.addMarker(item,'jcdw'))
      //       }
      //     })
      //   }else if(key==7){
      //     this.Map.clearMap();
      //     getAllUserdepLists({deptype:6}).then(res=>{
      //       if(res&&res.returncode=='0'){
      //         res.item.forEach(item=>this.addMarker(item,'sos'))
      //       }
      //     })
      //   }
      // },
      resetQueryParams(){
        this.userdepidCascaderSelected = [];
        this.maintenancedepidCascaderSelected = [];
        this.queryAreaSelected = [];
        this.$refs.queryForm.resetFields();
      },
      addMarker(item,type){
        if(item.coordinatey>-90&&item.coordinatey<90&&item.coordinatex>-180&&item.coordinatex<180){
          if(type==='dt'){
            var marker = new AMap.Marker({
              id:item.liftid,
              icon: new AMap.Icon({
                image: DtUnSelect,
                size: new AMap.Size(20, 24),
                imageSize: new AMap.Size(20, 24)
              }),
              position: [item.coordinatex,item.coordinatey],
              offset: new AMap.Pixel(-12,-12),
              title: item.liftid,
              map: this.Map
            })
            marker.item = {...item,type}
            marker.on('click', (e)=>this.markerClick(e,type));
          }
          else if(type==='sydw'){
            var marker = new AMap.Marker({
              id:item.userdepid,
              icon: new AMap.Icon({
                image: SydwSelect,
                size: new AMap.Size(20, 24),
                imageSize: new AMap.Size(20, 24)
              }),
              position: [item.coordinatex,item.coordinatey],
              offset: new AMap.Pixel(-12,-12),
              title: item.address,
              map: this.Map
            })
            marker.item = {...item,type}
            marker.on('click', (e)=>this.markerClick(e,type));
          }else if(type==='jgdw'){
            var marker = new AMap.Marker({
              id:item.userdepid,
              icon: new AMap.Icon({
                image: JgdwSelect,
                size: new AMap.Size(20, 24),
                imageSize: new AMap.Size(20, 24)
              }),
              position: [item.coordinatex,item.coordinatey],
              offset: new AMap.Pixel(-12,-12),
              title: item.address,
              map: this.Map
            })
            marker.item = {...item,type}
            marker.on('click', (e)=>this.markerClick(e,type));
          }
          else if(type==='unit'){
            var marker = new AMap.Marker({
              id:item.userdepid,
              icon: new AMap.Icon({
                image: UnitSelect,
                size: new AMap.Size(20, 24),
                imageSize: new AMap.Size(20, 24)
              }),
              position: [item.coordinatex,item.coordinatey],
              offset: new AMap.Pixel(-12,-12),
              title: item.address,
              map: this.Map
            })
            marker.item = {...item,type}
            marker.on('click', (e)=>this.markerClick(e,type));
          }
          else if(type==='jydw'){
            var marker = new AMap.Marker({
              id:item.userdepid,
              icon: new AMap.Icon({
                image: JydwSelect,
                size: new AMap.Size(20, 24),
                imageSize: new AMap.Size(20, 24)
              }),
              position: [item.coordinatex,item.coordinatey],
              offset: new AMap.Pixel(-12,-12),
              title: item.address,
              map: this.Map
            })
            marker.item = {...item,type}
            marker.on('click', (e)=>this.markerClick(e,type));
          }
          else if(type==='jcdw'){
            var marker = new AMap.Marker({
              id:item.userdepid,
              icon: new AMap.Icon({
                image: JcdwSelect,
                size: new AMap.Size(20, 24),
                imageSize: new AMap.Size(20, 24)
              }),
              position: [item.coordinatex,item.coordinatey],
              offset: new AMap.Pixel(-12,-12),
              title: item.address,
              map: this.Map
            })
            marker.item = {...item,type}
            marker.on('click', (e)=>this.markerClick(e,type));
          }else if(type==='sos'){
            var marker = new AMap.Marker({
              id:item.userdepid,
              icon: new AMap.Icon({
                image: SosSelect,
                size: new AMap.Size(20, 24),
                imageSize: new AMap.Size(20, 24)
              }),
              position: [item.coordinatex,item.coordinatey],
              offset: new AMap.Pixel(-12,-12),
              title: item.address,
              map: this.Map
            })
            marker.item = {...item,type}
            marker.on('click', (e)=>this.markerClick(e,type));
          }
        }
      },
      markerClick(e,type){
        // console.log(e.target.item)
        const item = e.target.item
        if(type.includes('self')&&(!item.coordinatex||!item.coordinatey||!(item.coordinatey>-90&&item.coordinatey<90&&item.coordinatex>-180&&item.coordinatex<180))){
          this.$message.warning('不存在对应坐标！');
          return
        }
        if(type==='dt'||type==='selfdt'){
          getLiftinfoById({
            liftid:item.liftid
          }).then(res=>{
            const {returncode,item} = res
            if(returncode==='0'){
              const info = this.getInfoTemplate(0,item)
              this.openInfoWindow([item.coordinatex,item.coordinatey],info)
            }
          })
        }else if(type==='sydw'||e.target.item.deptype===1){
          const info = this.getInfoTemplate(1,item)
          this.openInfoWindow([item.coordinatex,item.coordinatey],info)
        }else if(type==='jgdw'||e.target.item.deptype===2){
          const info = this.getInfoTemplate(2,item)
          this.openInfoWindow([item.coordinatex,item.coordinatey],info)
        }else if(type==='unit'||e.target.item.deptype===3){
          const info = this.getInfoTemplate(3,item)
          this.openInfoWindow([item.coordinatex,item.coordinatey],info)
        }else if(type==='jydw'||e.target.item.deptype===4){
          const info = this.getInfoTemplate(4,item)
          this.openInfoWindow([item.coordinatex,item.coordinatey],info)
        }else if(type==='jcdw'||e.target.item.deptype===5){
          const info = this.getInfoTemplate(5,item)
          this.openInfoWindow([item.coordinatex,item.coordinatey],info)
        }else if(type==='sos'||e.target.item.deptype===6){
          const info = this.getInfoTemplate(6,item)
          this.openInfoWindow([item.coordinatex,item.coordinatey],info)
        }
      },
      monitoringClick(){
      },
      getInfoTemplate(type,item){
        function monitoringClick(type){
          let prePages = [
            {
              name: '设施设备物联网',
              path: ''
            },{
              name: '电子地图',
              path: 'elevatorMap'
            }
          ];
          if(type==='monitoring'){
            if(item.deviceinfos && item.deviceinfos.length) {
              this.$router.push({
                name: 'timeLiftMonitoring',
                params: {
                  liftid: item.liftid,
                  liftnum: item.liftnum,
                  liftcode: item.liftcode,
                  deviceinfos: item.deviceinfos,
                  prePages: prePages
                }
              })
            }else {
              this.$message.error('该电梯无物联网设备');
            }
          }else if(type==='detail'){
            this.$router.push({
              name: 'elevatorArchivesDetail',
              params: {
                liftid: item.liftid,
                prePages: prePages
              }
            })
          }else{
            this.$message.warning('暂未开发，敬请期待');
          }
        }
        window.operationClick = monitoringClick.bind(this)
        if(type===0){
          return `<div style="width:350px">
            <div class="map-title" style="font-size:${this.fontSize*1.3}px">电梯信息</div>
            <div class="map-content" style="font-size:${this.fontSize}px">使用单位：<span>${item.userdepname}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保单位：<span>${item.maintenancedepname}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">安装地址：<span>${item.adress}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保总负责人电话：<span>${item?.maintenancedepLists?.safephone}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保负责人电话：<span>${item?.maintenancedepLists?.maintenancephone1}</span></div>
            <div style="margin-top:15px">
              <button type="button" class="ant-btn ant-btn-primary" onclick="operationClick('monitoring')"><span>实时监控</span></button>
<!--              <button type="button" class="ant-btn" onclick="operationClick('rescu')"><span>应急救援详情</span></button>-->
              <button type="button" class="ant-btn" onclick="operationClick('detail')"><span>电梯档案详情</span></button>
              </div>
          </div>`
        }else if(type==1) {
          return `<div style="width:300px">
            <div class="map-title" style="font-size:${this.fontSize * 1.3}px">使用单位信息</div>
            <div class="map-content" style="font-size:${this.fontSize}px">使用单位：<span>${item.userdepname}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">法人：<span>${item.legalperson}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">注册地址：<span>${item.address}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">安全管理员：<span>${item.safeperson}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">安全管理员电话：<span>${item.safephone}</span></div>
          </div>`
        }else if(type==2) {
          return `<div style="width:300px">
            <div class="map-title" style="font-size:${this.fontSize * 1.3}px">监管单位信息</div>
            <div class="map-content" style="font-size:${this.fontSize}px">监管单位：<span>${item.userdepname}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">法人：<span>${item.legalperson}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">注册地址：<span>${item.address}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">单位电话：<span>${item.telephone}</span></div>
          </div>`
        }else if(type==3) {
          return `<div style="width:300px">
            <div class="map-title" style="font-size:${this.fontSize * 1.3}px">维保单位信息</div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保单位：<span>${item.userdepname}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">法人：<span>${item.legalperson}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">注册地址：<span>${item.address}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保单位负责人：<span>${item.depmanager}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保单位负责人电话：<span>${item.depmanagerphone}</span></div>
          </div>`
        }else if(type==4) {
          return `<div style="width:300px">
            <div class="map-title" style="font-size:${this.fontSize * 1.3}px">检验单位信息</div>
            <div class="map-content" style="font-size:${this.fontSize}px">检验单位：<span>${item.userdepname}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">法人：<span>${item.legalperson}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">注册地址：<span>${item.address}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保单位负责人：<span>${item.depmanager}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保单位负责人电话：<span>${item.depmanagerphone}</span></div>
          </div>`
        }else if(type==5) {
          return `<div style="width:300px">
            <div class="map-title" style="font-size:${this.fontSize * 1.3}px">检测单位信息</div>
            <div class="map-content" style="font-size:${this.fontSize}px">维保单位：<span>${item.userdepname}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">法人：<span>${item.legalperson}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">注册地址：<span>${item.address}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">单位电话：<span>${item.telephone}</span></div>
          </div>`
        }else if(type==6) {
          return `<div style="width:300px">
            <div class="map-title" style="font-size:${this.fontSize * 1.3}px">救援单位信息</div>
            <div class="map-content" style="font-size:${this.fontSize}px">救援单位：<span>${item.userdepname}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">法人：<span>${item.legalperson}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">注册地址：<span>${item.address}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">二级救援站：<span>${item.depmanager}</span></div>
            <div class="map-content" style="font-size:${this.fontSize}px">二级救援站电话：<span>${item.depmanagerphone}</span></div>
          </div>`
        }
      },
      openInfoWindow(coorArr,info){
        const AMap = window.AMap
        let infoWindow = new AMap.InfoWindow({
          anchor: 'middle-left',
          offset: new AMap.Pixel(3, 0),
          content: info  //使用默认信息窗体框样式，显示信息内容
        });
        this.Map.setZoom(10)
        this.Map.panTo(coorArr)
        infoWindow.open(this.Map, coorArr);
      },
      closeInfoWindow(){
        this.Map.closeInfoWindow()
      },
      async getAllMapDatas(){
        let data = []
        const AMap = window.AMap
        const res0 = await getAllLiftinfoLists({})
        const res1 = await getAllUserdepLists({deptype:1})
        const res2 = await getAllUserdepLists({deptype:2})
        const res3 = await getAllUserdepLists({deptype:3})
        const res4 = await getAllUserdepLists({deptype:4})
        const res5 = await getAllUserdepLists({deptype:5})
        const res6 = await getAllUserdepLists({deptype:6})
        res0.item.forEach(item=>this.addMarker(item,'dt'))
        res1.item.forEach(item=>this.addMarker(item,'sydw'))
        res2.item.forEach(item=>this.addMarker(item,'jgdw'))
        res3.item.forEach(item=>this.addMarker(item,'unit'))
        res4.item.forEach(item=>this.addMarker(item,'jydw'))
        res5.item.forEach(item=>this.addMarker(item,'jcdw'))
        res6.item.forEach(item=>this.addMarker(item,'sos'))
        this.Map.setFitView()
      },
      getTableData(firstPage){
        if(firstPage) {
          this.currentPage = 1;
        }
        this.tableLoading = true;
        let requestUrl = getLiftinfoListByCondition
        if(this.currTab!='1'){
          requestUrl = getUserdepListByCondition
        }
        let params = {
          ...this.queryParams,
          deptype:this.deptype,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        requestUrl(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.showResult = true
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .bg-white{
    background-color: #fff;
  }
  .title{
    font-weight: bold;
    font-size: 20px;
    padding: 5px 20px;
  }
  .main-container{
    position: relative;
    margin-top: 10px;
    height: calc(100% - 127px);
  }
  .w-25 .ant-form-item,.w-25 .query-btns{
    width: 25%!important;
  }
  #elevatorMap{
    height: calc(100% - 75px);
    margin-top: 10px;
  }
  .left-box{
    position: absolute;
    left:20px;
    top:70px;
    width:278px;
    padding: 0 5px;
    height: calc(100% - 73px);
    background-color: #fff;
    z-index: 200;
  }
  .box-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }
  .cursor{
    cursor: pointer;
  }
  .box-title{
    color: #000;
    font-weight: 600;
    margin-left: 10px;
  }
</style>
<style lang="scss">
  .main-container{
    color: rgba(0, 0, 0, 0.85);
    .map-title{
      height: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .map-content{
      line-height: 24px;
      &>span{
        color: #bfbfbf;
      }
    }
  }
  .left-box{
    .ant-table-wrapper{
      height: calc(100% - 92px);
      overflow-y:auto
    }
  }
</style>